import $ from 'jquery';
import Alert from "./ComponentAlerts";
import {apiClient, URL_LOAD_TRAINING_TO_TV} from "./ApiClient";

$(function () {
    $('div.link_training_form input.link_training_send').click(function () {
        const index = $(this).data('index');
        const date = $('#link_training_day' + index).val();
        const startedAt = $('#link_training_start' + index).val();

        const data = {
            studioId: $('#link_training_studio' + index).val(),
            trainingId: $('#link_training_id' + index).val(),
            dateTime: `${date} ${startedAt}:00`,
        }

        apiClient.post(URL_LOAD_TRAINING_TO_TV, data).then((response) => {
            if (response.isSuccess()) {
                Alert.show(response.getMessage(), 'success');
            }

            if (response.isBadRequest()) {
                Alert.show('Training schedule failed' + response.getMessage(), 'danger');
            }

            if (response.isError()) {
                Alert.show('Unknown error', 'danger');
            }
        });
    });

    $('div.link_training_form input.link_training_send_now').click(function () {
        const index = $(this).data('index');

        const data = {
            studioId: $('#link_training_studio' + index).val(),
            trainingId: $('#link_training_id' + index).val()
        }

        apiClient.post(URL_LOAD_TRAINING_TO_TV, data).then((response) => {
            if (response.isSuccess()) {
                Alert.show(response.getMessage(), 'success');
            }

            if (response.isBadRequest()) {
                Alert.show('Training load failed. ' + response.getMessage(), 'danger');
            }

            if (response.isError()) {
                Alert.show('Unknown error', 'danger');
            }
        });
    });

    $('input.training_event_delete').click(function () {
        const trainingEventId = $(this).data('training-event-id');
        const data = {
            trainingEventId: trainingEventId,
        }
        delete_training_event(data);
    });
});