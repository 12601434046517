import $ from 'jquery';
import Alert from "./ComponentAlerts";

function startRebuild(id, callbackSuccess, callbackError) {
    return $.ajax({
        url: '/api/training/' + id + '/rebuild/',
        dataType: 'json'
    }).done(function (data) {
        if (data.status === 200) {
            Alert.show('Rendering started', 'success');
            if (typeof callbackSuccess === 'function') {
                callbackSuccess(data);
            }
        } else {
            Alert.show(data.error[0], 'danger');
            if (typeof callbackError === 'function') {
                callbackError(data);
            }
        }
    });
}

$(document).ready(function () {
    const $btn = $('*[data-event="start-rebuild"]');
    $btn.attr('disabled', false);

    $(document).on('click', '*[data-event="start-rebuild"]', function () {
        startRebuild($(this).data('id')).then(() => {
            location.reload();
        });
    });
});