import {apiClient, URL_LOAD_TRAINING_TO_TV, URL_TIMELINE_EVENT} from "./ApiClient";
import Alert from "./ComponentAlerts";

$(document).ready(() => {
    const $dataContainer = $('[data-container="studio-schedule"]');
    const $scheduleNavigator = $dataContainer.find('[data-role="schedule-navigator"]');
    const $btnTimelineControl = $dataContainer.find('[data-role="timeline-control"]');
    const $btnLoadToTv = $dataContainer.find('[data-role="load-to-tv"]');

    $scheduleNavigator.find('select').bind('change', function () {
        const year = $(this).closest('[data-role="schedule-navigator"]').find('[data-role="year-select"]').val();
        const month = $(this).closest('[data-role="schedule-navigator"]').find('[data-role="month-select"]').val();
        const studioCode = $(this).closest('[data-role="schedule-navigator"]').find('[data-role="studio-code"]').val();

        window.location.replace(`/studio/${studioCode}/schedule?year=${year}&month=${month}`);
    });

    $btnTimelineControl.bind('click', function(){
        const studioId = $(this).data('studio-id');
        const trainingId = $(this).data('training-id');
        const state = $(this).data('event-type');

        apiClient.post(URL_TIMELINE_EVENT, {
            trainingId,
            studioId,
            state,
        }).then((response) => {
            if (response.isSuccess()) {
                Alert.show(response.getMessage(), 'success');
            }

            if (response.isBadRequest()) {
                Alert.show('Timeline change failed. ' + response.getMessage(), 'danger');
            }
        })
    });

    $btnLoadToTv.bind('click', function() {
        const studioId = $(this).data('studio-id');
        const trainingId = $(this).data('training-id');

        apiClient.post(URL_LOAD_TRAINING_TO_TV, {studioId, trainingId}).then((response) => {
            if (response.isSuccess()) {
                Alert.show(response.getMessage(), 'success');

                location.reload();
            }

            if (response.isBadRequest()) {
                Alert.show('Training load failed. ' + response.getMessage(), 'danger');
            }

            if (response.isError()) {
                Alert.show('Unknown error', 'danger');
            }
        })
    })
});