import $ from 'jquery';
import {uuid} from "./helpers/uuid";
import {decimalHexTwosComplement} from "./helpers/converters";
import {faceSelect2Factory} from "./select2/FaceSelect2Factory";
import {bindModalLink} from "./helpers/modalForm";
import {createOverlaySpinner} from "./helpers/spinner";
import {ExerciseSelect2Factory} from "./select2/ExerciseSelect2Factory";

const CRC32 = require('crc-32/crc32');

const dndQueue = new Map();

$(document).bind('drop dragover', function (e) {
    e.preventDefault();
});

const createExerciseNameSelect = ($cardElement) => {
    const $nameElement = $cardElement.find('[name="name"]');
    const factory = new ExerciseSelect2Factory();
    const name = $nameElement.data('value');
    const $card = $nameElement.closest('.card');

    const fillControls = (exercise) => {
        $card.find('[name="isWarmUp"]').prop('checked', exercise.isWarmUp);
        $card.find('[name="isHiccup"]').prop('checked', exercise.isHiccup);
        $card.find('[name="repeats"]').val(exercise.repeats);
    }

    factory
        .preload({q: name})
        .then((rows) => {
            const exercises = rows;
            let newOption = null;

            if (exercises.length) {
                const exercise = exercises[0];
                newOption = new Option(exercise.text, exercise.id, false, true);
                newOption.dataset.raw = JSON.stringify(exercise);
                showVideosBadge(exercise.id);
                fillControls(exercise)
            } else {
                newOption = new Option($nameElement.data('value'), "_new", false, true);
                newOption.dataset.isNew = true;
                showNewBadge();
                fillControls({isWarmUp: false, isHiccup: false, repeats: null})
            }

            $card.find('[data-role="spinner"]').remove();

            factory.factory($nameElement);

            $nameElement.append(newOption).trigger('change');
        })


    const $exerciseVideosLink = $card.find('[data-role="exercise-videos-link"]');
    const $exerciseBadgeNew = $card.find('[data-role="new-exercise-badge"]');


    const showVideosBadge = (exerciseId) => {
        $card.addClass('existed');
        $exerciseVideosLink.attr('href', `/exercise/list-video/${exerciseId}`).removeClass('d-none');
        $exerciseVideosLink.bind('click', bindModalLink);
        $exerciseBadgeNew.addClass('d-none');
    }
    const showNewBadge = () => {
        $card.addClass('new');
        $exerciseBadgeNew.removeClass('d-none');
        $exerciseVideosLink.addClass('d-none');
    }

    $card.append(createOverlaySpinner());

    $nameElement.on('change', function () {
        const data = $(this).select2('data')[0];
        if (data && data.newOption || !data.id || data.id === '_new') {
            showNewBadge();
            fillControls({isWarmUp: false, isHiccup: false, repeats: null})
        } else {
            showVideosBadge(data.id);
            if (!data.element) { // event can trigger when element without completed data selected
                fillControls(data) 
            }
        }
    })
}

/**
 * Recalculate duplicates in upload queue
 */
const recalculateDndDuplicates = () => {
    const hashToUidMap = new Map();

    dndQueue.forEach((item) => {
        let hashCount = hashToUidMap.get(item.hash);

        if (!hashCount) {
            hashCount = 0;
        }

        hashCount++;

        hashToUidMap.set(item.hash, hashCount);
    });

    hashToUidMap.forEach((count, hash) => {
        const $elements = $(`[data-type="dnd-item"][data-hash="${hash}"] [data-video-upload-duplicate-badge]`);

        if (count > 1) {
            $elements.removeClass('d-none');
        } else {
            $elements.addClass('d-none');
        }
    });
}

const dndClear = () => {
    $('*[data-target="dnd-container"]').html('');
    dndQueue.clear();
}

const dndCreate = () => {
    const itemsTotal = dndQueue.size;

    $('[data-event="dnd"] [data-state="manage"]').addClass('d-none');
    $('[data-event="dnd"] [data-state="loading"]').removeClass('d-none');

    dndQueue.forEach((item, uuid) => {
        const $element = $(`[data-type="dnd-item"][data-idx="${uuid}"]`);

        const exerciseData = $element.find(`[name="name"]`).select2('data')[0];
        const fileName = $element.find(`[name="fileName"]`).val();
        const faceData = $element.find(`[name="face"]`).select2('data')[0] ?? null;
        const faceId = faceData ? faceData.id : '';
        const repeats = $element.find(`[name="repeats"]`).val();
        const isWarmUp = $element.find(`[name="isWarmUp"]`).is(':checked');
        const isHiccup = $element.find(`[name="isHiccup"]`).is(':checked');

        const file = item.file;

        let formData = new FormData();
        let url;

        if (exerciseData && exerciseData.id && !exerciseData.newOption && exerciseData.id !== '_new') {
            // create video for old exercise
            url = '/exercise-video/create-for-exercise/'
            formData.append('exercise_video_form[videoFile]', file);
            formData.append('exercise_video_form[exercise]', exerciseData.id);
            formData.append('exercise_video_form[fileName]', fileName);
            formData.append('exercise_video_form[face]', faceId);
            formData.append('exercise_item_attributes_form[repeats]', repeats);
            if (isWarmUp) {
                formData.append('exercise_item_attributes_form[isWarmup]', isWarmUp);
            }
            if (isHiccup) {
                formData.append('exercise_item_attributes_form[isHiccup]', isHiccup);
            }
        } else {
            // create new exercise with videofile
            url = '/exercise/create/';
            formData.append('exercise_item_form[videoFile]', file);
            formData.append('exercise_item_form[fileName]', fileName);
            formData.append('exercise_item_form[name]', exerciseData.text);
            formData.append('exercise_item_form[face]', faceId);
            formData.append('exercise_item_form[repeats]', repeats);
            if (isWarmUp) {
                formData.append('exercise_item_form[isWarmup]', isWarmUp);
            }
            if (isHiccup) {
                formData.append('exercise_item_form[isHiccup]', isHiccup);
            }
        }

        $.ajax({
            url: url,
            method: 'POST',
            contentType: false,
            processData: false,
            data: formData,
            success: function () {
                dndQueue.delete(uuid);
                if (dndQueue.size < 1) {
                    location.reload();
                }

                const $progressBar = $('[data-event="dnd"] [role="progressbar"]');
                const percentDone = 100 - (dndQueue.size * 100 / itemsTotal);

                $progressBar.attr('aria-valuenow', percentDone);
                $progressBar.css({width: `${percentDone}%`});
            },
            error: function (data) {
                if (data.status !== 200) {
                    console.error('Error on saving dnd exercise', data.responseJSON);
                }

                dndQueue.delete(uuid);

                if (dndQueue.size < 1) {
                    location.reload();
                }
            }
        })
    })
}


/**
 * Check duplicates stored on server
 *
 * @param queueItem
 */
function checkDuplicates(queueItem) {
    const {hash, uuid} = queueItem;

    const formData = new FormData();

    formData.append('hash', hash);

    $.ajax({
        url: '/exercise/upload/dnd-check-duplicates/',
        method: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        success: function (data) {
            const $element = $(`[data-type="dnd-item"][data-idx="${uuid}"]`);
            const $duplicateLinkElement = $element.find('[data-video-id-duplicate-link]');

            $element.attr('data-hash', hash);
            $duplicateLinkElement.attr('href', '/exercise/?hash=' + hash);

            if (data.hasDuplicates) {
                $duplicateLinkElement.removeClass('d-none');
            }

            recalculateDndDuplicates();
        }
    });


}

function dndProcessor(items, _this) {

    for (let i = 0; i < items.length && i < 30; i++) {

        if (items[i].kind === 'file' || items[i].kind === undefined) {
            const fileUuid = uuid();
            let file = (items[i].kind === undefined) ? items[i] : items[i].getAsFile();

            // read file and check crc32
            const reader = new FileReader();
            reader.onload = function () {
                const buf = this.result;
                const arrayBuf = new Uint8Array(buf);

                const crc32Sum = CRC32.buf(arrayBuf);
                const crc32SumHex = decimalHexTwosComplement(crc32Sum);

                const queueItem = {
                    uuid: fileUuid,
                    name: file.name,
                    file: file,
                    hash: crc32SumHex,
                };

                dndQueue.set(fileUuid, queueItem)

                checkDuplicates(queueItem);
            }
            reader.readAsArrayBuffer(file);

            const originName = (file.name).replace(/[\+|\s]+|(\.mp4)/ig, ' ').trim();

            const $element =
                $('<div></div>')
                    .addClass('col-3 mb-4')
                    .attr('data-type', 'dnd-item')
                    .attr('data-idx', fileUuid)
                    .append(
                        $('<div></div>')
                            .addClass('card text-start')
                            .append(
                                $('<div></div>')
                                    .addClass('card-body')
                                    .append(
                                        $('<button />')
                                            .addClass('btn-close float-end')
                                            .attr('type', 'button')
                                            .attr('date-event', 'dnb-item-close')
                                    )
                                    .append(
                                        $('<label for="exampleFormControlInput1" class="form-label">Exercise name</label>')
                                    )
                                    .append(
                                        $('<select/>')
                                            .data('value', originName)
                                            .addClass('form-control form-control-sm mb-1')
                                            // .attr('type', 'text')
                                            .attr('name', 'name')
                                    )
                                    .append(
                                        $('<a>Show exercise videos</a>')
                                            .attr('target', '_blank')
                                            .attr('data-role', 'exercise-videos-link')
                                            .attr('data-exercise-id', null)
                                            .attr('data-event', 'modal-editor')
                                            .attr('href', '/exercise/list-video/{exercise}')
                                            .addClass('badge rounded-pill bg-primary d-none')
                                    )
                                    .append(
                                        $('<span>New</span>')
                                            .attr('data-role', 'new-exercise-badge')
                                            .addClass('badge rounded-pill bg-success d-none')
                                    )
                                    .append('<br/>')
                                    .append(
                                        $('<label for="exampleFormControlInput1" class="form-label mt-2">File name</label>')
                                    )
                                    .append(
                                        $('<input/>')
                                            .addClass('form-control form-control-sm mb-1')
                                            .attr('type', 'text')
                                            .attr('name', 'fileName')
                                            .val(originName)
                                    )
                                    .append(
                                        $('<input/>')
                                            .addClass('d-none')
                                            .attr('type', 'text')
                                            .attr('data-video-id', null)
                                            .attr('name', 'videoFileId')
                                    )
                                    .append(
                                        $('<a>Duplicate video file</a>')
                                            .attr('target', '_blank')
                                            .attr('data-video-id-duplicate-link', fileUuid)
                                            .attr('href', '/exercise/?hash=' + $('*[data-video-id="' + i + '"]').data('hash'))
                                            .addClass('badge rounded-pill bg-danger d-none')
                                    )
                                    .append(
                                        $('<span>Duplicate upload</span>')
                                            .attr('data-video-upload-duplicate-badge', true)
                                            .addClass('badge rounded-pill bg-warning d-none')
                                    )
                                    .append(
                                        '<br/>'
                                    )
                                    .append(
                                        $('<label for="exampleFormControlInput1" class="form-label mt-2">Face</label>')
                                    )
                                    .append(
                                        $('<select/>')
                                            .addClass('form-control form-control-sm mb-1')
                                            .attr('type', 'text')
                                            .attr('name', 'face')
                                    )
                                    .append(
                                        $('<label class="form-label mt-2">Repeats</label>')
                                    )
                                    .append(
                                        $('<input/>')
                                            .addClass('form-control form-control-sm mb-1')
                                            .attr('type', 'text')
                                            .attr('placeholder', 'x2 x5 x3')
                                            .attr('name', 'repeats')
                                    )
                                    .append(
                                        $('<div class="row mt-2">\n' +
                                            '<div class="col">\n' +
                                            '<div class="form-check">\n' +
                                            `  <input name="isWarmUp" class="form-check-input" type="checkbox" value="" id="is-warm-up-${i}">\n` +
                                            `  <label class="form-check-label" for="is-warm-up-${i}">\n` +
                                            '    Warm Up\n' +
                                            '  </label>\n' +
                                            '</div>\n' +
                                            '</div>\n' +
                                            '<div class="col">\n' +
                                            '<div class="form-check">\n' +
                                            `  <input name="isHiccup" class="form-check-input" type="checkbox" value="" id="is-warm-is-hiccup-${i}">\n` +
                                            `  <label class="form-check-label" for="is-warm-is-hiccup-${i}">\n` +
                                            '    Cooldown\n' +
                                            '  </label>\n' +
                                            '</div>\n' +
                                            '</div>\n' +
                                            '</div>'
                                        )
                                    )
                                    .append(
                                        $('<video></video>')
                                            .addClass('w-100')
                                            .css('height', '185px')
                                            .attr('controls', '')
                                            .append('<source />')
                                            .attr('src', URL.createObjectURL(file))
                                    )
                            )
                    );
            $(_this).find('*[data-target="dnd-container"]').append($element);

            createExerciseNameSelect($element);

            faceSelect2Factory.factory($element.find('[name="face"]'));
        }
    }
}

$(document).ready(function () {
    $(document).on('click', '*[data-event="open-file-dialog"]', function () {
        $('#multiple-file-selector').click();
    });

    $('#multiple-file-selector').change(function () {
        let files = this.files;
        let element = $('*[data-event="dnd"]');
        dndProcessor(files, element);
    })

    $(document).on('click', '#dnd-clear', dndClear);

    $(document).on('click', '#dnd-save', dndCreate);

    $(document).on('click', '*[date-event="dnb-item-close"]', function () {
        const $card = $(this).closest('[data-type="dnd-item"]');
        const uuid = $card.data('idx');

        dndQueue.delete(uuid);
        $card.remove();

        recalculateDndDuplicates();
    });

    $('*[data-event="dnd"]').on({
        dragenter: function () {
            $(this).addClass('shadow');
        },
        dragleave: function () {
            $(this).removeClass('shadow');
        },
        drop: function (e) {
            e.preventDefault();
            $(this).removeClass('shadow');
            if (e.originalEvent.dataTransfer.items) {
                dndProcessor(e.originalEvent.dataTransfer.items, this);
            }
        }
    });
})
;