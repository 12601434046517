import $ from "jquery";
import {apiClient, URL_LOAD_TRAINING_TO_TV, URL_TIMELINE_EVENT} from "./ApiClient";
import Alert from "./ComponentAlerts";

$(document).ready(function() {
     const $container = $(this).find('[data-container="trainer-container"]');
     const $loadToTvButton = $container.find('[data-role="load-to-tv-button"]');
     const $btnTimelineControl = $container.find('[data-role="timeline-control"]');

     $loadToTvButton.bind('click', function() {
          const data = {
               studioId: $(this).data('studio-id'),
               trainingId: $(this).data('training-id')
          }

          apiClient.post(URL_LOAD_TRAINING_TO_TV, data).then((response) => {
               if (response.isSuccess()) {
                    Alert.show(response.getMessage(), 'success');
                    location.reload();
               }

               if (response.isBadRequest()) {
                    Alert.show('Load failed.' + response.getMessage(), 'danger');
               }

               if (response.isError()) {
                    Alert.show('Unknown error', 'danger');
               }
          })
     });

     $btnTimelineControl.bind('click', function(){
          const studioId = $(this).data('studio-id');
          const trainingId = $(this).data('training-id');
          const state = $(this).data('event-type');

          apiClient.post(URL_TIMELINE_EVENT, {
               trainingId,
               studioId,
               state,
          }).then((response) => {
               if (response.isSuccess()) {
                    Alert.show(response.getMessage(), 'success');
               }

               if (response.isBadRequest()) {
                    Alert.show('Timeline change failed. ' + response.getMessage(), 'danger');
               }
          })
     });
});