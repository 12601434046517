import $ from 'jquery';

export default {
    show: (message, type) => {
        $('#liveAlertPlaceholder').append(
            $('<div></div>')
                .addClass('alert alert-' + type + ' alert-dismissible')
                .attr('role', 'alert')
                .html(message)
            .append(
                $('<button />')
                    .addClass('btn-close')
                    .attr('type', 'button')
                    .attr('data-bs-dismiss', 'alert')
                    .attr('aria-label', 'Close')
            )
        );
    }
}