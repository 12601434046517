import $ from 'jquery';
import {Modal} from "bootstrap";

$(function () {


    $(document).on('click', '*[data-event="create-type"]', function () {
        $('form[name="training_type_form"]').attr('action', '/training-type/create/');
        $('#training_type_form_name').val('');
        $('#training_type_form_logo').val('');
        $('#training-type-preview').attr('src');
        Modal.getOrCreateInstance(document.getElementById('training-edit-modal')).show();
    });

    $(document).on('click', '*[data-event="edit-type"]', function () {
        $('form[name="training_type_form"]').attr('action', '/training-type/edit/' + $(this).data('id') + '/');
        $('#training_type_form_name').val($(this).data('name'));
        $('#training-type-preview').attr('src', $(this).data('preview'))
        Modal.getOrCreateInstance(document.getElementById('training-edit-modal')).show();
    });

    $(document).on('change', '#training_type_form_logo', function () {
        const [file] = this.files;
        const targetId = '#training-type-preview';
        let $target = $(targetId);

        if (file) {
            let FileRender = new FileReader();

            FileRender.onload = function (event) {
                $($target).attr('src', event.target.result);
            }

            FileRender.readAsDataURL(file);
        }
    });
});