/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import * as Sentry from "@sentry/browser";
import './sentry_integration.js';
import './styles/fonts.css';
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'select2'
import 'select2/dist/css/select2.css';
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";
import "sticky-table-headers/js/jquery.stickytableheaders.js";
import "inputmask/dist/inputmask";

import './js/Tools';
import './js/ScreenSchemeChoice';
import './js/TrainingPlanChoice';
import './js/ExerciseEditor';
import './js/ExerciseFileItemEditor';
import './js/TrainingFilter';
import './js/TrainingType';
import './js/Training';
import './js/Dnd';
import './js/TrainingEvents';
import './js/TabataEditor';
import './js/Timeline/TimelineEditor';
import './js/ExerciseSelector';
import './js/TvMessageEditor';
import './js/Schedule';
import './js/Trainer';
import './js/StudioManage';
import './js/AdvertisingForm';
import './js/helpers/pageSwitcher';

import {bindModalLink} from './js/helpers/modalForm';
import $ from "jquery";
import {bindCommonElementControls} from "./js/helpers/pageSwitcher";

$(document).ready(function () {
    $('*[data-select2-enabled]').select2({
        'theme': 'bootstrap-5',
        'width': 'auto'
    });

    $('[data-sticky-headers]').stickyTableHeaders();

    $('[data-event="modal-editor"]').bind('click', bindModalLink)

    $('[data-toggle="collapse"]').collapse({
        toggle: false
    });
    $('[data-btn="run-js-bug"]').on('click', () => {
        try{
            throw new Error('some bad error');
        } catch (e){
            Sentry.captureException(e);
        }
        const el = document.getElementById('bad-element');
        el.innerHTML = '...';
    });

    $(document).on('modal:rendered', function (e, $element) {
        setTimeout(() => {
            $element.find('[data-select2-enabled]').each(function () {
                $(this).select2({
                    'theme': 'bootstrap-5',
                    dropdownParent: $element
                });
            });
        }, 100);
    });

    $(document).ready(function () {
        bindCommonElementControls($(this));
    })
});