import $ from "jquery";
import {bindCommonElementControls} from "./helpers/pageSwitcher";
import Alert from "./ComponentAlerts";
import {formItemsToJson} from "./helpers/converters";

let originLapTemplate = null;
let originZoneTemplate = null;
let originSetTemplate = null;
let originalWorkoutStepTemplate = null;

let tvResults = {
    1: {
        timeTotal: 0,
        zones: {}
    },
    2: {
        timeTotal: 0,
        zones: {}
    },
    3: {
        timeTotal: 0,
        zones: {}
    },
};

/**
 * @param tvId
 * @param zoneId
 * @param lapId
 * @param {Object} lapObject
 * @param {int} lapObject.restTime
 * @param {Array} lapObject.sets
 * @param {int} lapObject.sets.workTime
 * @param {int} lapObject.sets.restTime
 * @returns {string}
 */
const renderLap = (tvId, zoneId, lapId, lapObject) => {
    if (originLapTemplate === null) {
        const $lapTemplate = $('#_lap-template');
        originLapTemplate = $lapTemplate[0].outerHTML.toString();
    }

    const {trainingSchemeChange} = lapObject;

    let timeTotal = lapObject.restTime;
    let lapTemplate = originLapTemplate;
    let setContent = '';

    lapObject.sets.forEach(function (s, setId) {
        setContent += renderSet(tvId, zoneId, lapId, setId, s);
    });

    lapObject.sets.forEach(function (s) {
        timeTotal += s.restTime + s.workTime;
    })

    let restTime = lapObject.restTime.toString();

    return lapTemplate
        .replace(/\{\{tvId}}/g, tvId)
        .replace(/\{\{zoneId}}/g, zoneId)
        .replace(/\{\{lapId}}/g, lapId)
        .replace(/\{\{lapNumber}}/g, lapId + 1)
        .replace(/\{\{sets}}/g, setContent)
        .replace(/\{\{restTime}}/g, restTime)
        .replace(/\{\{timeTotal}}/g, secondsToTime(timeTotal))
        .replace(/\{\{lap_to_scheme_id}}/g, trainingSchemeChange ? trainingSchemeChange.toSchemeId : null)
        .replace(/\{\{lapToSchemeOutputType}}/g, trainingSchemeChange ? trainingSchemeChange.outputType : null)
        .replace(/\{\{lapToSchemeIndex}}/g, trainingSchemeChange ? trainingSchemeChange.index : null)
        .replace(/\{\{lap_explanation_type}}/g, trainingSchemeChange ? trainingSchemeChange.explanationType : null)
        .replace(/\{\{lap_tv_message_state}}/g, lapObject.isNoMessage ? 'checked' : '')

}

/**
 * @param {int} zoneObject.laps
 * @returns {string}
 * @param tvId
 * @param zoneId
 * @param zoneObject
 */
const renderZone = (tvId, zoneId, zoneObject) => {
    if (originZoneTemplate === null) {
        const $zoneTemplate = $('#_zone-template');
        originZoneTemplate = $zoneTemplate[0].outerHTML.toString();
    }

    let zoneTemplate = originZoneTemplate;
    let lapsContent = '';

    zoneObject.laps.forEach(function (l, lapId) {
        lapsContent += renderLap(tvId, zoneId, lapId, l);
    })

    let restTime = zoneObject.restTime.toString();

    return zoneTemplate
        .replace(/\{\{tvId}}/g, tvId)
        .replace(/\{\{zoneId}}/g, zoneId)
        .replace(/\{\{zoneNumber}}/g, zoneId + 1)
        .replace(/\{\{laps}}/g, lapsContent)
        .replace(/\{\{restTime}}/g, restTime)
        .replace(/\{\{timeTotal}}/g, 0);
}

/**
 *
 * @returns {string}
 * @param tvId
 * @param zoneId
 * @param lapId
 * @param setId
 * @param workoutStepId
 * @param workoutStepObject
 */
const renderWorkoutStep = (tvId, zoneId, lapId, setId, workoutStepId, workoutStepObject) => {
    const $template = $('#_workout-step-template');
    const timeTotal = workoutStepObject.workTime + workoutStepObject.restTime;
    let restTime = workoutStepObject.restTime;
    let workTime = workoutStepObject.workTime;
    const {trainingSchemeChange} = workoutStepObject;

    if (originalWorkoutStepTemplate === null) {
        originalWorkoutStepTemplate = $template.prop('outerHTML').toString();
    }

    return originalWorkoutStepTemplate
        .replace(/\{\{workoutStepId}}/g, workoutStepId)
        .replace(/\{\{setId}}/g, setId)
        .replace(/\{\{lapId}}/g, lapId)
        .replace(/\{\{zoneId}}/g, zoneId)
        .replace(/\{\{tvId}}/g, tvId)
        .replace(/\{\{workoutStepNumber}}/g, workoutStepId + 1)
        .replace(/\{\{restTime}}/g, restTime)
        .replace(/\{\{workTime}}/g, workTime)
        .replace(/\{\{timeTotal}}/g, secondsToTime(timeTotal))
        .replace(/\{\{workout_step_to_scheme_id}}/g, trainingSchemeChange ? trainingSchemeChange.toSchemeId : null)
        .replace(/\{\{workout_step_output_type}}/g, trainingSchemeChange ? trainingSchemeChange.outputType : null)
        .replace(/\{\{workout_step_to_scheme_index}}/g, trainingSchemeChange ? trainingSchemeChange.index : null)
        .replace(/\{\{workout_step_explanation_type}}/g, trainingSchemeChange ? trainingSchemeChange.explanationType : null)
        ;
}

/**
 *
 * @param tvId
 * @param zoneId
 * @param setId
 * @param lapId
 * @param setObject
 * @returns {string}
 */
const renderSet = (tvId, zoneId, lapId, setId, setObject) => {
    const {workoutSteps, isComboSet, isTripleSet} = setObject;
    const $setTemplate = $('#_set-template');
    let workoutStepsContent = '';
    const {trainingSchemeChange} = setObject;

    const timeTotal = 0;

    if (originSetTemplate === null) {
        originSetTemplate = $setTemplate.prop('outerHTML').toString();
    }

    workoutSteps.forEach(function (workoutStep, workoutStepId) {
        workoutStepsContent += renderWorkoutStep(tvId, zoneId, lapId, setId, workoutStepId, workoutStep);
    })

    return originSetTemplate
        .replace(/\{\{combo_checkbox_state}}/g, isComboSet ? 'checked' : '')
        .replace(/\{\{triple_checkbox_state}}/g, isTripleSet ? 'checked' : '')
        .replace(/\{\{setId}}/g, setId)
        .replace(/\{\{lapId}}/g, lapId)
        .replace(/\{\{zoneId}}/g, zoneId)
        .replace(/\{\{tvId}}/g, tvId)
        .replace(/\{\{setNumber}}/g, setId + 1)
        .replace(/\{\{timeTotal}}/g, secondsToTime(timeTotal))
        .replace(/\{\{workoutSteps}}/g, workoutStepsContent)
        .replace(/\{\{set_to_scheme_id}}/g, trainingSchemeChange ? trainingSchemeChange.toSchemeId : null)
        .replace(/\{\{lap_to_scheme_id}}/g, trainingSchemeChange ? trainingSchemeChange.outputType : null)
        .replace(/\{\{set_to_scheme_index}}/g, trainingSchemeChange ? trainingSchemeChange.index : null)
        .replace(/\{\{set_explanation_type}}/g, trainingSchemeChange ? trainingSchemeChange.explanationType : null)
        .replace(/\{\{set_tv_message_state}}/g, setObject.isNoMessage ? 'checked' : '')
        ;
}

/**
 *
 * @param $container
 */
const bindEditorControls = ($container) => {
    bindCommonElementControls($container);

    $container.find('[data-btn="tv-clone"]').unbind('click').bind('click', function () {
        const tvIdFrom = $(this).closest('[data-tabata-plan-editor]').data('tv-id');
        const tvIdTo = $(this).data('tv-id');

        const $tabataTreeTo = $(`[data-tabata-tree][data-tv-id="${tvIdTo}"]`);
        const $tabataTreeFrom = $(`[data-tabata-tree][data-tv-id="${tvIdFrom}"]`);

        $tabataTreeFrom.find('select').unbind('change');

        const $clonedTree = $tabataTreeFrom.clone(false, false);

        if (confirm(`Are you sure you want to clone tabata from TV${tvIdFrom} to TV${tvIdTo}`)) {
            $tabataTreeFrom.find('select').each(function (index, element) {
                const selectName = $(element).attr('name');
                $clonedTree.find(`select[name="${selectName}"]`).val($(element).val());
            });

            $clonedTree.data('tv-id', tvIdTo);
            $clonedTree.attr('data-tv-id', tvIdTo);

            $tabataTreeTo.replaceWith($clonedTree);

            bindEditorControls($clonedTree);
            updateDynamicControls($clonedTree);

            bindEditorControls($tabataTreeFrom);
            updateDynamicControls($tabataTreeFrom);
        }
    });

    $container.find('[data-btn="collapse-all"]').unbind('click').bind('click', function () {
        $container.find('[data-role="card-collapse-button"]').each(function () {
            if ($(this).data('collapse-state') === 'expanded') {
                $(this).click();
            }
        })
    });

    $container.find('[data-btn="expand-all"]').unbind('click').bind('click', function () {
        $container.find('[data-role="card-collapse-button"]').each(function () {
            if ($(this).data('collapse-state') === 'collapsed') {
                $(this).click();
            }
        })
    });

    $container.find('[data-btn="zone-clone"]').unbind('click').bind('click', function () {
        const $element = $(this).parents('[data-zone-id]');
        const $clonedElement = $element.clone();

        $element.find('select').each(function (index, element) {
            const selectName = $(element).attr('name');
            $clonedElement.find(`select[name="${selectName}"]`).val($(element).val());
        });

        $clonedElement.insertAfter($element);
        $clonedElement.data('zone-id', $clonedElement.data('zone-id') + 1);

        bindEditorControls($clonedElement);
        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    })

    $container.find('[data-btn="lap-clone"]').unbind('click').bind('click', function () {
        const $element = $(this).parents('[data-lap-id]');
        const $clonedElement = $element.clone();

        $element.find('select').each(function (index, element) {
            const selectName = $(element).attr('name');
            $clonedElement.find(`select[name="${selectName}"]`).val($(element).val());
        });

        $clonedElement.insertAfter($element);
        $clonedElement.data('lap-id', $clonedElement.data('lap-id') + 1);

        bindEditorControls($clonedElement);
        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    })

    $container.find('[data-btn="zone-remove"]').unbind('click').bind('click', function () {
        const $element = $(this).parents('[data-zone-id]');
        const $editor = $(this).parents('[data-tabata-plan-editor]');

        if ($element.next('[data-zone-id]').length || $element.prev('[data-zone-id]').length) {
            $element.remove();
        }

        updateDynamicControls($editor)
    })

    $container.find('[data-btn="lap-remove"]').unbind('click').bind('click', function () {
        const $lapElement = $(this).parents('[data-lap-id]');

        if ($lapElement.next('[data-lap-id]').length || $lapElement.prev('[data-lap-id]').length) {
            $lapElement.remove();
        }

        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    })

    $container.find('[data-btn="set-clone"]').unbind('click').bind('click', function () {
        const $element = $(this).parents('[data-set-id]');
        const $clonedElement = $element.clone();

        $element.find('select').each(function (index, element) {
            const selectName = $(element).attr('name');
            $clonedElement.find(`select[name="${selectName}"]`).val($(element).val());
        });

        $element.find('input[type="checkbox"]').each(function (index, element) {
            const name = $(element).attr('name');
            if ($(element).is(':checked')) {
                $clonedElement.find(`input[type="checkbox"][name="${name}"]`).attr('checked', 'checked');
            }
        });

        $element.find('checkbox').each(function (index, element) {
            const selectName = $(element).attr('name');
            $clonedElement.find(`select[name="${selectName}"]`).val($(element).val());
        });

        $clonedElement.insertAfter($element);

        bindEditorControls($clonedElement);
        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    })

    $container.find('[data-btn="set-remove"]').unbind('click').bind('click', function () {
        const $setElement = $(this).parents('[data-set-id]');
        const $editor = $(this).parents('[data-tabata-plan-editor]');

        if ($setElement.next('[data-set-id]').length || $setElement.prev('[data-set-id]').length) {
            $setElement.remove();
        }

        updateDynamicControls($editor)
    })

    $container.find('[data-btn="workout-step-clone"]').unbind('click').bind('click', function () {
        const $element = $(this).closest('[data-workout-step-id]');
        const $clonedElement = $element.clone();

        $element.find('select').each(function (index, element) {
            const selectName = $(element).attr('name');
            $clonedElement.find(`select[name="${selectName}"]`).val($(element).val());
        });

        $element.find('input[type="checkbox"]').each(function (index, element) {
            const name = $(element).attr('name');
            if ($(element).is(':checked')) {
                $clonedElement.find(`input[type="checkbox"][name="${name}"]`).attr('checked', 'checked');
            }
        });


        $clonedElement.insertAfter($element);

        bindEditorControls($clonedElement);
        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    })

    $container.find('[data-btn="workout-step-remove"]').unbind('click').bind('click', function () {
        const $element = $(this).parents('[data-workout-step-id]');
        const $editor = $(this).parents('[data-tabata-plan-editor]');

        if ($element.next('[data-workout-step-id]').length || $element.prev('[data-workout-step-id]').length) {
            $element.remove();
        }

        updateDynamicControls($editor)
    });

    $container.find('[data-input]').unbind('change').bind('change', function () {
        updateDynamicControls($(this).parents('[data-tabata-plan-editor]'))
    });

    $container.find('[data-role="combo-checkbox"]').bind('change', function () {
        const isTripleSet = $(this).data('type') === 'triple';
        const isChecked = $(this).is(':checked');

        if (isChecked) {
            let $checkboxToDisable;

            if (isTripleSet) {
                $checkboxToDisable = $(this).closest('[data-set-id]').find('[data-role="combo-checkbox"][data-type=combo]');
            } else {
                $checkboxToDisable = $(this).closest('[data-set-id]').find('[data-role="combo-checkbox"][data-type="triple"]');
            }
            $checkboxToDisable.prop('checked', null);
        }
    });

    $container.find('[data-role="scheme-switcher"]').each(function () {
        const $schemeSelect = $(this).find('[data-role="scheme-select"]');
        const $typeSelect = $(this).find('[data-role="type-select"]');
        const $indexSelect = $(this).find('[data-role="index-select"]');
        const $explanationTypeSelect = $(this).find('[data-role="explanation-type-select"]');

        $schemeSelect.unbind('change').bind('change', function () {
            const val = $(this).val();
            if (val) {
                $typeSelect.find('option[value=""]').remove();
                $indexSelect.find('option[value=""]').remove();
                $typeSelect.removeAttr('disabled');
                $indexSelect.removeAttr('disabled');
                $explanationTypeSelect.removeAttr('disabled');
                if (!$explanationTypeSelect.val()) {
                    $explanationTypeSelect.val('standard');
                }
            } else {
                $typeSelect.prepend(new Option("-", '')).val('');
                $indexSelect.prepend(new Option("-", '')).val('');
                $typeSelect.attr('disabled', 'disabled');
                $indexSelect.attr('disabled', 'disabled');
                $explanationTypeSelect.attr('disabled', 'disabled');
                $explanationTypeSelect.val('');
            }
        })


        if ($schemeSelect.data('value')) {
            $schemeSelect.val($schemeSelect.data('value')).removeAttr('data-value');
        }

        if ($typeSelect.data('value')) {
            $typeSelect.val($typeSelect.data('value')).removeAttr('data-value');
        }

        if ($indexSelect.data('value')) {
            $indexSelect.val($indexSelect.data('value')).removeAttr('data-value');
        }

        if ($explanationTypeSelect.data('value')) {
            $explanationTypeSelect.val($explanationTypeSelect.data('value')).removeAttr('data-value');
        }

        $indexSelect.trigger('change');
        $schemeSelect.trigger('change', {doNotTouchExplanations: true});
    })

    $container.parents('form').unbind('submit').bind('submit', function (e) {
        e.preventDefault();

        const $submitBtn = $('#btn-submit-tabta');

        $submitBtn.attr('disabled', 'disabled');

        $(this).find('[data-role="scheme-select"]').each(function () {
            if (!$(this).val()) {
                $(this).attr('disabled', 'disabled');
            }
        })

        const data = formItemsToJson($(this).get(0));

        $.ajax({
            url: $(this).attr('action'),
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function () {
                location.replace('/tabata/index');
            },
            error: function (xhr) {
                const data = xhr.responseJSON;

                if (data.message) {
                    Alert.show(data.message, 'danger');
                }

                $submitBtn.removeAttr('disabled');
            }
        });
    })

}

/**
 *
 * @param $editor
 */
const updateDynamicControls = ($editor) => {
    $editor.find('[data-zone-id]').each(function (zoneIndex) {
        $(this).data('zone-id', zoneIndex);
        $(this).prop('data-zone-id', zoneIndex);

        $(this).find('[data-lap-id]').each(function (lapIndex) {
            $(this).data('lap-id', lapIndex);
            $(this).prop('data-lap-id', lapIndex);

            $(this).find('[data-set-id]').each(function (setIndex) {
                $(this).data('set-id', setIndex);
                $(this).prop('data-set-id', setIndex);
                $(this).find('[name="fuck"]').val(setIndex)

                $(this).find('[data-workout-step-id]').each(function (workoutStepIndex) {
                    $(this).data('workout-step-id', workoutStepIndex);
                    $(this).prop('data-workout-step-id', workoutStepIndex);
                });
            });
        });
    });

    $editor.find('[data-text="lap-title"]').each(function () {
        const lapId = $(this).parents('[data-lap-id]').data('lap-id');
        $(this).text('Lap ' + (lapId + 1));
    })

    $editor.find('[data-text="set-title"]').each(function () {
        const setId = $(this).parents('[data-set-id]').data('set-id');

        $(this).text('Set ' + (setId + 1));
    })

    $editor.find('[data-zone-id]').each(function () {
        const timeTotal = calculateZoneTimeTotal($(this));
        const zoneId = $(this).data('zone-id');
        $(this).find('[data-text="zone-time-total"]').text(secondsToTime(timeTotal));
        $(this).find('[data-text="zone-title"]').text('Zone ' + (zoneId + 1));
    });

    $editor.find('[data-lap-id]').each(function () {
        const timeTotal = calculateLapTimeTotal($(this));

        $(this).find('[data-text="lap-time-total"]').text(secondsToTime(timeTotal));
    });

    $editor.find('[data-set-id]').each(function () {
        const timeTotal = calculateSetTimeTotal($(this));

        $(this).find('[data-text="set-time-total"]').text(secondsToTime(timeTotal));
    });

    setTimeout(() => {
        $editor.find('[data-name-template]').each(function () {
            let nameTemplate = $(this).data('name-template');
            const formName = $(this).parents('form').prop('name');
            const tvId = $(this).parents('[data-tv-id]').data('tv-id');
            const zoneId = $(this).parents('[data-zone-id]').data('zone-id');
            const lapId = $(this).parents('[data-lap-id]').data('lap-id');
            const setId = $(this).parents('[data-set-id]').data('set-id');
            const workoutStepId = $(this).parents('[data-workout-step-id]').data('workout-step-id');

            const name = nameTemplate
                .replace(/\{\{_tvId}}/, tvId)
                .replace(/\{\{_zoneId}}/, zoneId)
                .replace(/\{\{_lapId}}/, lapId)
                .replace(/\{\{_setId}}/, setId)
                .replace(/\{\{_workoutStepId}}/, workoutStepId)
                .replace(/\{\{_formName}}/, formName)
            ;

            $(this).attr('name', name);
        });

    }, 50)

    const tvId = $editor.data('tv-id');
    const tvTimeTotal = calculateTvTimeTotal($editor, tvId);
    const tvZonesTotal = calculateTvZonesTotal($editor);

    $(`[data-role="tv-time-total"][data-tv-id="${tvId}"]`).text(secondsToTime(tvTimeTotal));
    $(`[data-role="tv-zones-total"][data-tv-id="${tvId}"]`).text(tvZonesTotal);

    compareTvTimings();
}

const compareTvTimings = () => {

    const firstTvResult = tvResults[1];
    const warnings = [];
    const $output = $('[data-role="alert-text"]');

    for (let tvId in tvResults) {
        const tvResult = tvResults[tvId];

        if (tvId === 1) {
            continue;
        }

        if (firstTvResult.timeTotal !== tvResult.timeTotal) {
            warnings.push(`The total workout time for TV${tvId} does not match the total time on TV1.`)
        }

        if (firstTvResult.zones.length !== tvResult.zones.length) {
            warnings.push(`The total number of zones for TV${tvId} does not match the total number of zones on TV1.`)
        }

        for (let zoneId in tvResult.zones) {

            const tvZone = tvResult.zones[zoneId];
            const tv1Zone = firstTvResult.zones[zoneId] ?? null;

            if (tv1Zone && tv1Zone.timeTotal !== tvZone.timeTotal) {
                warnings.push(`The total time of zone ${parseInt(zoneId) + 1} for TV${tvId} does not match the total time of zone ${parseInt(zoneId) + 1} on TV1.`)
            }
        }
    }

    $output.html('');
    $output.addClass('d-none');

    if (warnings.length > 0) {
        let html = '';

        warnings.forEach((warning) => {
            html += '<i class="bi-exclamation-triangle-fill"></i> ' + warning + '<br/>';
        });

        $output.html(html);
        $output.removeClass('d-none');
    }
}

/**
 *
 * @param $setElement
 * @returns {number}
 */
const calculateSetTimeTotal = ($setElement) => {
    let timeTotal = 0;

    $setElement.find('[data-input="workout-work-time"]').each(function () {
        timeTotal += parseInt($(this).val());
    })

    $setElement.find('[data-input="workout-rest-time"]').each(function () {
        timeTotal += parseInt($(this).val());
    })

    return timeTotal;
}

/**
 *
 * @param $element
 * @param tvId
 * @returns {number}
 */
const calculateTvTimeTotal = ($element, tvId) => {
    let total = 0;

    tvResults[tvId].zones = {};

    $element.find('[data-zone-id]').each(function (index) {

        const zoneTime = calculateZoneTimeTotal($(this));

        total += zoneTime;

        tvResults[tvId].zones[index] = {timeTotal: zoneTime};
    })

    tvResults[tvId].timeTotal = total;

    return total;
}

/**
 *
 * @param $element
 * @returns {number}
 */
const calculateTvZonesTotal = ($element) => {
    let total = 0;

    $element.find('[data-zone-id]').each(function () {
        total += 1;
    })

    return total;
}

/**
 *
 * @param $lapElement
 * @returns {number}
 */
const calculateLapTimeTotal = ($lapElement) => {
    let lapTime = parseInt($lapElement.find('[data-input="lap-rest-time"]').val());
    $lapElement.find('[data-set-id]').each(function () {
        lapTime += calculateSetTimeTotal($(this));
    })

    return lapTime;
}

/**
 *
 * @param $zoneElement
 * @returns {number}
 */
const calculateZoneTimeTotal = ($zoneElement) => {
    let zoneTime = parseInt($zoneElement.find('[data-input="zone-rest-time"]').val());

    $zoneElement.find('[data-lap-id]').each(function () {
        zoneTime += calculateLapTimeTotal($(this));
    })

    return zoneTime;
}

/**
 * @param $mainContainer
 * @param tvId
 * @param {Object} tabataTvConfig
 * @param {Array} tabataTvConfig.laps
 */
const renderEditor = ($mainContainer, tvId, tabataTvConfig) => {
    const $tabataTree = $mainContainer.find('[data-tabata-tree]');

    if (!$mainContainer.length) {
        return;
    }

    $('#tabata_update_form_plan').remove();
    $('#tabata_create_form_plan').remove();

    $tabataTree.empty();

    tabataTvConfig.zones.forEach(function (z, zoneId) {
        const zoneTemplate = renderZone(tvId, zoneId, z);
        $tabataTree.append(
            zoneTemplate
        )
    })

    bindEditorControls($mainContainer);
    updateDynamicControls($mainContainer)
}

/**
 *
 * @param secs
 * @returns {`${string}:${string}`}
 */
const secondsToTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = secs % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

$(document).ready(() => {
    const $editors = $('[data-tabata-plan-editor]');

    if ($editors.length) {
        const config = JSON.parse($('#plan-source').val());

        $editors.each(function () {
            const tvId = $(this).data('tv-id');
            renderEditor($(this), tvId, config.tvConfigs[tvId]);
        })
    }
});
