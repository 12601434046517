const createFormSpinner = () => {
    return `<div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>`;
}

createOverlaySpinner = () => {
    return `<div data-role="spinner" class="overlay-spinner-container">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>`;
}

exports.createFormSpinner = createFormSpinner;
exports.createOverlaySpinner = createOverlaySpinner;