const {DateTime} = require("luxon");
exports.decimalHexTwosComplement = (decimal) => {
    const size = 8;

    if (decimal >= 0) {
        let hexadecimal = decimal.toString(16);

        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }

        return hexadecimal;
    } else {
        let hexadecimal = Math.abs(decimal).toString(16);
        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }

        let output = '';
        for (let i = 0; i < hexadecimal.length; i++) {
            output += (0x0F - parseInt(hexadecimal[i], 16)).toString(16);
        }

        output = (0x01 + parseInt(output, 16)).toString(16);
        return output;
    }
}

exports.formatTime = (time) => {
    const date = new Date(time * 1000);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return hours + ':' + minutes + ':' + seconds;
}


exports.convertFixedSecondsToTimezone = (seconds, fromTimezone, toTimezone) => {
    const secondsFrom = getTzOffsetInSeconds(fromTimezone);
    const secondsTo = getTzOffsetInSeconds(toTimezone);

    const diffInSeconds = secondsTo - secondsFrom;

    return seconds + diffInSeconds;
}

const getTzOffsetInSeconds = (timeZone) => {
    const dt = DateTime.local().setZone(timeZone);
    return dt.offset * 60;
};

exports.getTzOffsetInSeconds = getTzOffsetInSeconds;


exports.formItemsToJson = (FormElement) => {
    let formDataEntries = new FormData(FormElement).entries();
    const handleChild = function (obj, keysArr, value) {
        let firstK = keysArr.shift();
        firstK = firstK.replace(']', '');
        if (keysArr.length === 0) {
            if (firstK === '') {
                if (!Array.isArray(obj)) obj = [];
                obj.push(value);
            } else obj[firstK] = value;
        } else {
            if (firstK === '') obj.push(value);
            else {
                if (!(firstK in obj)) obj[firstK] = {};
                obj[firstK] = handleChild(obj[firstK], keysArr, value);
            }
        }
        return obj;
    };
    let result = {};
    for (const [key, value] of formDataEntries)
        result = handleChild(result, key.split(/\[/), value);
    return result;
}