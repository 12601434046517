const $ = require("jquery");
const {Modal} = require("bootstrap");

const commonModalId = 'modal-common';

const updateModalContent = (content, isReady) => {
    const $modalElement = $(`#${commonModalId}`);
    $(`#${commonModalId} .modal-dialog .modal-content`).replaceWith(content);

    if (isReady) {
        $(document).trigger('modal:rendered', [$modalElement])
    }
}

const showModalForm = (url, setupCallback) => {
    updateModalContent(
        `<div class="modal-content">
        <div class="modal-body text-center">
            <div class="spinner-border" role="status"></div>
        </div>
    </div>`
    );

    const modalElement = document.getElementById(commonModalId);
    Modal.getOrCreateInstance(modalElement).show();

    $(modalElement).on('hidden.bs.modal', function () {
        const $videoElement = $(this).find('video')

        if ($videoElement.length) {
            $videoElement.get(0).pause();
        }
    });

    $.ajax({
        url: url
    }).done(function (content, status, data) {
        if (status === 'success') {
            updateModalContent(content, true);
            setupForm();
            if (setupCallback) {
                setupCallback();
            }
        } else {
            console.error('Error on loading modal content.', data);
        }
    });
}

const setupForm = () => {
    const $form = $(`#${commonModalId} form`);

    let submitType = $form.data('submit-type');

    if (!submitType) {
        submitType = 'ajax';
    }

    if (submitType === 'ajax') {
        $form.each(function () {
            $(this).unbind('submit');
            $(this).bind('submit', function (e) {
                e.preventDefault();

                const action = $(this).attr('action');
                const data = new FormData(this); // Use FormData to include file data

                $(this).find('[type=submit]').prop('disabled', true);

                $.ajax({
                    type: "POST",
                    url: action,
                    data: data,
                    processData: false, // Prevent jQuery from processing data
                    contentType: false, // Let the browser set the correct content type
                    success: function () {
                        window.location.reload();
                    },
                    error: function (data) {
                        if (data.status === 400) {
                            updateModalContent(data.responseText);
                            setupForm();
                        } else {
                            console.error('Unexpected response', data);
                        }
                    }
                });
            });
        })
    } else {
        $form.bind('submit', function () {
            $form.find('[type=submit]').prop('disabled', true);
        });
    }
}

exports.bindModalLink = function (e) {
    e.preventDefault();
    const formUrl = $(this).attr('href');
    showModalForm(formUrl)
}
