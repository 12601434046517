$(document).ready(() => {
    const $dataContainer = $('[data-container="advertising-manager"]');
    if (!$dataContainer.length) {
        return;
    }

    const $form = $dataContainer.find('form');
    const $btnSubmit = $dataContainer.find('form [type="submit"]');

    $form.on('submit', function() {
        $btnSubmit.attr('disabled', 'disabled');
    });
});