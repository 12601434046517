const bindCommonElementControls = ($element) => {
    $element.find('[data-role="page-switch"]').each(function () {
        const $pills = $(this).find('a');
        const containers = [];

        $pills.each(function () {
            const containerId = $(this).attr('href');
            containers.push($(containerId));

            $(this).unbind('click').bind('click', function (e) {
                e.preventDefault();

                containers.forEach((c) => {
                    c.addClass('d-none');
                });

                $(containerId).removeClass('d-none');

                $pills.removeClass('active');

                $(this).addClass('active');
            });

            if($(this).hasClass('active')) {
                $(this).click();
            }
        })
    })

    $element.find('[data-role="card-collapse-button"]').each(function () {
        const $card = $(this).closest('.card');

        $(this).unbind('click').bind('click', function () {
            const collapseState = $(this).data('collapse-state');

            if (collapseState === 'expanded') {
                $card.find('.card-body').first().addClass('d-none');
                $(this).data('collapse-state', 'collapsed');
                $(this).html('<bi class="bi-chevron-right"></bi>');
            } else {
                $card.find('.card-body').first().removeClass('d-none');
                $(this).data('collapse-state', 'expanded');
                $(this).html('<bi class="bi-chevron-down"></bi>');
            }
        })

        const collapseState = $(this).data('collapse-state');

        if (collapseState === 'collapsed') {
            $card.find('.card-body').first().addClass('d-none');
        }
    })
}

exports.bindCommonElementControls = bindCommonElementControls;