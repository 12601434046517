exports.objectToQueryParams = (obj) => {
    const encodedParams = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    const encodedValue = encodeURIComponent(value[i]);
                    encodedParams.push(key + '[]=' + encodedValue);
                }
            } else {
                const encodedValue = encodeURIComponent(value);
                encodedParams.push(key + '=' + encodedValue);
            }
        }
    }

    return encodedParams.join('&');
}

const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const queryParams = {};

    for (const [key, value] of params.entries()) {
        queryParams[key] = value;
    }

    return queryParams;
}

exports.getQueryParams = getQueryParams;

exports.getQueryParam = (name) => {
    const queryParams = getQueryParams();
    return queryParams[name];
}