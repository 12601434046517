import $ from 'jquery';
import Alert from "./ComponentAlerts";

function getTrainingDates(month, year, callbackSuccess, callbackError) {
    $.ajax({
        url: '/api/training/dates/' + month + '/' + year,
        dataType: 'json'
    }).done(function (data) {
        if (data.status === 200) {
            if (typeof callbackSuccess === 'function') {
                callbackSuccess(data);
            }
        } else {
            Alert.show(data.error[0], 'danger');
            if (typeof callbackError === 'function') {
                callbackError(data);
            }
        }
    });
}

function generateDayList(dayLimit, currentDay, highlights) {

    let container = $('<ul></ul>').addClass('pagination');
    currentDay = currentDay || -1;
    for (let i = 0; i < dayLimit; i++) {
        container.append(
            $('<li></li>')
                .addClass('page-item')
                .addClass(currentDay === (i + 1) ? 'active' : '')
                .append(
                    $('<a></a>')
                        .addClass('page-link')
                        .addClass(highlights.includes((i+1).toString()) ? 'pagination-highlight' : '')
                        .attr('href', '#change-day')
                        .attr('data-event', 'select-day')
                        .attr('data-value', i + 1)
                        .text(i + 1)
                )
        );
    }

    return $('<nav></nav>').append(container);
}

function prepareForm() {
    $('form[name="training_search_form"]')
        .attr('data-year', parseInt($('#training_search_form_year').val()))
        .attr('data-month', parseInt($('#training_search_form_month').val()))
        .attr('data-day', parseInt($('#training_search_form_day').val()))
}

$(function () {
    $(document).on('change', '*[data-event="change-date"]', function () {
        const $form = $('form[name="training_search_form"]');

        const month = parseInt($form.data('month'));
        const year = parseInt($form.data('year'));
        const day = parseInt($form.data('day'));
        const selectedMonth = parseInt($('#training_search_form_month').val());
        const selectedYear = parseInt($('#training_search_form_year').val());
        const selectedDay = (month === selectedMonth && year === selectedYear) ? day : -1;

        getTrainingDates(selectedMonth, selectedYear, function (data) {
            const daysCount = new Date(selectedYear, selectedMonth, 0).getDate();

            $('#day-navigator').html(generateDayList(daysCount, selectedDay, data.data));
        });
    });

    $(document).on('click', '*[data-event="select-day"]', function () {
        $('#training_search_form_day').val(
            parseInt($(this).data('value'))
        );

        $('form[name="training_search_form"]').submit();

        return false;
    });

    $(document).ready(function () {
        prepareForm();
        $('#training_search_form_year').change();
    });
});