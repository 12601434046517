import {BaseSelect2Factory} from "./BaseSelect2Factory";
import $ from "jquery";

export class ExerciseFileItemSelect2Factory extends BaseSelect2Factory {
    ajaxUrl() {
        return '/select2/exercise-file';
    }

    getPlaceholder() {
        return "Please select exercise video..."
    }

    factory($element) {
        const fileItemId = $element.data('file-item-id');
        const fileItemFileName = $element.data('file-item-file-name');
        const fileItemName = $element.data('file-item-name');
        const exerciseName = $element.data('exercise-name');

        const option = new Option(exerciseName, fileItemId, true, true);

        if ($element.is('input')) {
            const $input = $element;
            const $select = $('<select/>');

            // Копируем все атрибуты из <input> в <select>
            $.each($input[0].attributes, function (index, attribute) {
                $select.attr(attribute.name, attribute.value);
            });

            // Проверяем наличие data-атрибутов и копируем их
            $.each($input.data(), function (key, value) {
                $select.data(key, value);
            });

            // Заменяем <input> на <select>
            $input.replaceWith($select);

            $element = $select;
        }

        option.dataset.raw = JSON.stringify({
            id: fileItemId,
            fileName: fileItemName,
            exerciseName: exerciseName,
            img: fileItemFileName ? `/preview/${fileItemFileName}.gif` : '/file/preview/-1',
        });

        $element.append(option).trigger('change');

        $element.on('select2:open', function () {
            const data = $(this).select2('data');

            if (!data || !data[0]) {
                return;
            }

            const fileItemName = data[0].exerciseName;

            if (fileItemName) {
                $('input.select2-search__field').val(fileItemName);
            }
        })

        return super.factory($element);
    }

    templateResult = (exerciseFileItem) => {
        exerciseFileItem = ExerciseFileItemSelect2Factory.getRealData(exerciseFileItem);

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return '';
        }

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return 'Loading...';
        }

        return $(`<div class="exercise-file-card">
                            <div class="img">
                                <img src="${exerciseFileItem.img}" alt="" />
                            </div>
                            <div class="info-block">
                                <div class="names-block">
                                    <div class="text-exercise-name">${exerciseFileItem.exerciseName}</div>
                                    <div class="text-file-name">${exerciseFileItem.fileName}</div>
                                </div>
                            </div>
                        </div>`);
    }

    templateSelection(exerciseFileItem) {
        exerciseFileItem = ExerciseFileItemSelect2Factory.getRealData(exerciseFileItem);

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return '';
        }

        return $(`<div class="exercise-file-card">                            
                            <div class="info-block">
                                <div class="names-block">
                                    <div class="text-exercise-name">${exerciseFileItem.exerciseName}</div>
                                    <div class="text-file-name">${exerciseFileItem.fileName}</div>
                                </div>
                            </div>
                        </div>`);
    }

    static BindToNearbyMiniImage($element) {
        $element.on('change', function () {
            let data = $element.select2('data')[0];
            data = BaseSelect2Factory.getRealData(data);

            const src = data && data.img !== '' ? data.img : '/file/preview/-1';

            const $img = $(this).closest('[data-role="exercise-row"]').find('img');

            $img.attr('src', src);
        })

        $element.trigger('change');
    }
}
