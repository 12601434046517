import $ from 'jquery';

function parseFormula(formula) {
    let map = {
        'col': 0,
        'row': 0,
        'nf': false,
        'nfMap': []
    };


    let parts = formula.split('/');
    formula = parts[0];

    formula.split(':').forEach(item => {
        if (item.indexOf('c') !== -1) {
            map.col = parseInt(item.replace('c', ''));
        } else if (item.indexOf('r') !== -1) {
            map.row = parseInt(item.replace('r', ''));
        }
    });

    if (parts.length > 1) {

        map.nf = parts.length > 1;
        map.nfMap = [];
        parts.slice(1).forEach(item => {

            if (item === 'nf') {
                item += map.col * map.row;
            }

            map.nfMap.push(parseInt(item.replace('nf', '')));
        });
    }

    return Object.assign({}, map);
}

function createSchemeView(tvIndex, formula) {

    const map = parseFormula(formula);
    let container = $('<table><tbody></tbody></table>')
        .addClass('table table-bordered');

    let screenId = 1;
    let cellItemId = 1;
    for (let r = 0; r < map.row; r++) {
        let row = $('<tr></tr>');

        for (let c = 0; c < map.col; c++) {
            let TVCellId = (r + 1) + 'r' + (c + 1) + 'c';
            let col = $('<td></td>');

            if (!(map.nf && map.nfMap.indexOf(cellItemId) !== -1)) {
                col.append(
                    $('<h5></h5>')
                        .addClass('text-end mb-2')
                        .append(
                            $('<span></span>')
                                .addClass('badge bg-secondary')
                                .text(cellItemId)
                        )
                        .append(' <small>' + TVCellId + '</small>')
                ).append(
                    $('<label></label>')
                        .append('Station Label')
                ).append(
                    $('<input>')
                        .addClass('form-control form-control-sm text-center')
                        .attr('type', 'string')
                        .attr('data-target', 'screen-item')
                        .attr('data-screen-index', TVCellId)
                        .attr('data-formula', formula)
                        .attr('data-display-id', tvIndex)
                ).append(
                    $('<div></div>')
                        .addClass('invalid-feedback')
                        .attr('data-id', 'error-description')
                        .html('&nbsp;')
                );
                screenId++;
            }
            cellItemId++;
            row.append(col);
        }

        container.append(row);
    }

    return container;
}

function serializeScreenConfig() {
    let config = {};
    let errorCount = 0;

    $(document).find('*[data-target="screen-item"]').each(function () {
        let tvIndex = parseInt($(this).data('display-id'));
        let screenId = $(this).data('screen-index');
        let screenVal = $(this).val();
        let screenFormula = $(this).data('formula');
        let errorDescription = $(this).parent().find('*[data-id="error-description"]');

        if (screenVal === '') {
            $(this).addClass('is-invalid');
            $(errorDescription).html('Need set station id');

            errorCount++;
        } else {
            $(this).removeClass('is-invalid').addClass('is-valid');
            $(errorDescription).html('&nbsp;');
        }

        config[tvIndex] = config[tvIndex] || {
            tv_index: 0,
            formula: '',
            map: {}
        };

        config[tvIndex].tv_index = tvIndex;
        config[tvIndex].formula = screenFormula;
        config[tvIndex].map[screenId] = '' + (screenVal);
    });

    if (errorCount) {
        throw "invalid data";
    }

    return config;
}

function deserializeConfig() {
    const config = JSON.parse($('#training_scheme_form_screenSettings').val());

    for (let idx in config) {
        let $tvIndex = $('*[data-tv-index="' + config[idx].tv_index + '"]');
        $tvIndex.val(config[idx].formula);
        $tvIndex.change();

        for (let sidx in config[idx].map) {
            $tvIndex.parent().parent().find('*[data-screen-index="' + sidx + '"]').val(config[idx].map[sidx]);
        }
    }
}

$(function () {
    $('*[data-toggle="screen-scheme-choice"]').on('change', function () {
        const tvIndex = $(this).data('tv-index');
        let $screenForm = $(this).parent().parent().find('*[data-target="screen-form"]');
        $screenForm.html(createSchemeView(tvIndex, $(this).val()));
    });

    $('#training-scheme-form').submit(function () {
        let config;

        try {
            config = serializeScreenConfig();
        } catch (e) {
            return false;
        }

        $('#training_scheme_form_screenSettings').val(JSON.stringify(config));

        return true;
    });

    $(document).on('change', '*[data-toggle=image-preview]', function () {
        const [file] = this.files;
        const targetId = '*[data-id="' + $(this).data('target') + '"]';
        let $target = $(targetId);

        if (file) {
            let FileRender = new FileReader();

            FileRender.onload = function (event) {
                $($target).attr('src', event.target.result);
            }

            FileRender.readAsDataURL(file);
        }
    });

    $(document).on('change', '#training_scheme_form_type', function () {
        $('*[data-id="logo-preview"]').attr('src', '/file/type/' + $(this).val());
    });
    $('#training_scheme_form_type').change();

    if ($(document).has('.training-scheme-edit-form').length) {
        deserializeConfig();
    }

});