import {formatTime} from "../helpers/converters";

const applyStyleControl = ($control, cssParamTarget) => {
    const cssParamValue = $control.val();

    let target = $control.data('font-target');
    let targets = [];

    if (target === 'all') {
        targets = ['title', 'progress'];
    } else {
        targets = [target];
    }


    targets.forEach((target) => {
        const $example = $control.closest('[data-role="style-editor"]').find(`[data-font-example="${target}"]`);

        const cssUpdate = {
            [cssParamTarget]: cssParamValue
        };

        $example.css(cssUpdate);
    });
}

$(document).ready(function () {
    const $labelForCustomOrder = $('[for="timeline_form_step_exercise_explanations_customStationOrder"]');
    const $textAreaCustomOrder = $('#timeline_form_step_exercise_explanations_customStationOrder');

    console.log($labelForCustomOrder)
    if ($labelForCustomOrder) {
        const $btn = $('<button type="button" class="btn btn-outline-dark btn-sm">Fill from tabata</button>');

        $btn.on('click', function() {
            const $tabataSelect = $('#timeline_form_step_workout_tabata');
            if($tabataSelect) {
                const tabataId = $tabataSelect.val();
                console.log('ta', tabataId);
                $.ajax({
                    url: `/tabata/explanations-station-order/${tabataId}`,
                    type: 'get',
                    success: function (response) {
                        console.log('re', response);
                        $textAreaCustomOrder.text('');
                        
                        let text = '';
                        
                        response.forEach((item) => {
                            text += `${item.trainingSchemeId}:${item.index}:${item.stationLabel}\n`;
                        })

                        $textAreaCustomOrder.text(text);
                    },
                    error: function (xhr, status, error) {
                        console.error(error);
                    }
                });
            }
        });

        $labelForCustomOrder.append($btn);
    }


    $('[data-role="font-control"]').on('change', function () {
        applyStyleControl($(this), $(this).data('css-target'))
    });

    $('[data-role="font-control"]').trigger('change');

    $('[data-role="apply-to-all"]').on('click', function () {
        const $controls = $(this).closest('[data-role="style-editor"]').find('[data-role="font-control"]');

        if (confirm('Are you sure?')) {
            $controls.each(function () {
                const controlType = $(this).data('control');

                $(this).closest('form').find(`[data-control="${controlType}"]`).val($(this).val()).trigger('change');
            });
        }
    });

    $('[data-role="time-control"]').on('change', function () {
        const $form = $(this).closest('form');
        const url = $form.data('check-time-action');
        const method = $form.attr('method');

        $.ajax({
            url: url,
            type: method,
            data: $form.serialize(),
            success: function (response) {
                $('[data-timing="durationTotal"]').text(formatTime(response.durationTotal));
                $('[data-timing="durationWithoutHiccup"]').text(formatTime(response.durationWithoutHiccup));

                $(`[data-role="duration"]`).text('-');

                for (let key in response.durationBySteps) {
                    const stepType = key;
                    const duration = response.durationBySteps[key];

                    $(`[data-role="duration"][data-step-type="${stepType}"]`).text(formatTime(duration));
                }
            },
            error: function (xhr, status, error) {
                console.error(error);
            }
        });
    }).first().trigger('change');
})

