import $ from 'jquery';
import {createFormSpinner} from "./helpers/spinner";
import {ExerciseFileItemSelect2Factory} from "./select2/FileItemSelect2Factory";

// переносит данные из левых контролов, загружающих форму в правую часть
const mapValues = {
    '#training_manage_form_eventDatetime': '#training_form_eventDatetime'
};

// выбиралка таймлайна после которой загружается форма
const controlTimelineSelect = '#training_manage_form_timeline';

// поле с ID тренировки
const controlTrainingIdHidden = '#training_manage_form_id';

const showLoading = () => {
    const $container = $('#training-plan');
    $container.children().addClass('d-none');
    $container.append(createFormSpinner());
}

/**
 * Редактируем или создаем
 *
 * @returns {boolean}
 */
const isTrainingEdit = () => {
    return $(controlTrainingIdHidden).val() > 0;
}

/**
 * Обработчик загрузки HTML формы создания тренировки на страницу
 * 1. Заполняем контент при ответе
 * 2. Навешиваем хендлер отправки
 *
 * @param response
 */
const updateForm = (response) => {
    const $container = $('#training-plan');
    $container.html(response);

    $container.find('form').bind('submit', function (e) {
        const $form = $(this);

        e.preventDefault();

        const action = $form.attr('action');
        const data = new FormData(this);

        $form.find('[type=submit]').prop('disabled', true);

        showLoading();

        let currentUrl = window.location.href;
        let url = new URL(currentUrl);
        let date = url.searchParams.get('date');
        let redirectUrl = '/training'

        if (date) {
            const dateParts = date.split('-');

            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            const params = new URLSearchParams();
            params.set('training_search_form[day]', day);
            params.set('training_search_form[month]', month);
            params.set('training_search_form[year]', year);

            redirectUrl += `?${params.toString()}`;
        }

        $.ajax({
            type: "POST",
            url: action,
            data: data,
            processData: false,
            contentType: false,
            success: function () {
                window.location.href = redirectUrl;
            },
            error: function (data) {
                if (data.status === 400) {
                    updateForm(data.responseText);
                } else {
                    console.error('Unexpected response', data);
                }
            }
        });
    });

    for (const from in mapValues) {
        const fromValue = $(from).val();
        $(mapValues[from]).val(fromValue);
    }

    makeSelect2Inputs();
}

/**
 * Метод загружает реальную форму для создания и редактирования тренировки (правая часть)
 * Когда мы выбрали параметры в левой части, форма будет отрисована в правой
 *
 * @param timelineId
 * @param trainingId
 */
const loadRealForm = (timelineId, trainingId) => {
    showLoading();

    let url;

    if (trainingId) {
        url = `/training/edit/${timelineId}/${trainingId}`;
    } else {
        url = `/training/create/${timelineId}`;
    }

    $.ajax({
        url: url,
        type: 'GET',
        success: function (response) {
            updateForm(response);
        },
        error: function (xhr, status, error) {
            console.error(error);
        }
    });
}

const makeSelect2Inputs = () => {
    $('*[data-select2-enabled]').select2({
        'theme': 'bootstrap-5'
    });

    const fileItemsAdditionalFilter = () => {
        const filter = {};

        $('[data-role="filter-field"]').each(function () {
            const formField = $(this).attr('name');
            const value = $(this).val();

            if (formField) {
                filter[formField] = value;
            }
        })

        return filter;
    }

    $('[data-role="exercise-file-item-value"]').each(function () {
        const $element = (new ExerciseFileItemSelect2Factory)
            .setAdditionalFilter(fileItemsAdditionalFilter)
            .factory($(this));
        ExerciseFileItemSelect2Factory.BindToNearbyMiniImage($element);
    })

}

$(function () {
    // при изменении выпадашки timeline загружается форма
    $(controlTimelineSelect).change(function () {
        const timelineId = $(this).find(':selected').val();
        const trainingId = $(controlTrainingIdHidden).val();

        // есть таймлайн, загружаем форму
        if (timelineId) {
            loadRealForm(timelineId, trainingId);
        } else {
            // таймлайн не выбран, очищаем форму
            updateForm('');
        }
    });

    if (isTrainingEdit() || $(controlTimelineSelect).val()) {
        // если это редактирование, сразу загруаем форму
        $(controlTimelineSelect).trigger('change');
    }
});