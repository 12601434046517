const RESPONSE_TYPE_SUCCESS = 'success';
const RESPONSE_TYPE_ERROR = 'error';
const RESPONSE_TYPE_BAD_REQUEST = 'badRequest';
const RESPONSE_TYPE_UNKNOWN = 'unknown';

const URL_LOAD_TRAINING_TO_TV = '/api/trainer/load-training';
const URL_TIMELINE_EVENT = '/api/trainer/timeline-event';

class ApiResponse {
    constructor(data) {
        this.data = data || {};
        this.type = RESPONSE_TYPE_UNKNOWN;
    }

    getMessage() {
        return this.data.message ?? '';
    }

    isSuccess() {
        return this.type === RESPONSE_TYPE_SUCCESS;
    }

    isError() {
        console.log('is error', this.type === RESPONSE_TYPE_ERROR);
        return this.type === RESPONSE_TYPE_ERROR;
    }

    isBadRequest() {
        return this.type === RESPONSE_TYPE_BAD_REQUEST;
    }
}

class ApiClient {
    makeRequest(url, requestParams, data, method) {
        return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url, true);

            // Установка заголовков
            if (requestParams.headers) {
                for (const [key, value] of Object.entries(requestParams.headers)) {
                    xhr.setRequestHeader(key, value);
                }
            }

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    let responseData = null;

                    try {
                        responseData = JSON.parse(xhr.responseText);
                    } catch (e) {
                        const apiResponse = new ApiResponse({});
                        apiResponse.type = RESPONSE_TYPE_ERROR;
                        resolve(apiResponse);
                    }

                    const apiResponse = new ApiResponse(responseData);

                    if (xhr.status >= 200 && xhr.status < 300) {
                        apiResponse.type = RESPONSE_TYPE_SUCCESS;
                        resolve(apiResponse);
                    } else if (xhr.status === 400) {
                        apiResponse.type = RESPONSE_TYPE_BAD_REQUEST;
                        resolve(apiResponse);
                    } else {
                        apiResponse.type = RESPONSE_TYPE_ERROR;
                        resolve(apiResponse);
                    }
                }
            };

            // Преобразование данных в строку для POST-запросов
            const requestData = (method === 'POST' || method === 'PUT') ? JSON.stringify(data) : null;

            xhr.send(requestData);
        });
    }

    post(url, data, requestParams = {}) {
        requestParams.method = 'POST';
        return this.makeRequest(url, requestParams, data, 'POST');
    }

    get(url, data, requestParams = {}) {
        requestParams.method = 'GET';
        return this.makeRequest(url, requestParams, data, 'GET');
    }
}

exports.apiClient = new ApiClient();
exports.RESPONSE_TYPE_SUCCESS = RESPONSE_TYPE_SUCCESS;
exports.RESPONSE_TYPE_ERROR = RESPONSE_TYPE_ERROR;
exports.RESPONSE_TYPE_BAD_REQUEST = RESPONSE_TYPE_BAD_REQUEST;

exports.URL_LOAD_TRAINING_TO_TV = URL_LOAD_TRAINING_TO_TV;
exports.URL_TIMELINE_EVENT = URL_TIMELINE_EVENT;
