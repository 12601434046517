const bindTvMessageEditor = ($tvMessageEditor) => {
    const $messageEditors = $tvMessageEditor.find('[data-role="tv-message-editor"]');

    const updateExample = ($editor) => {
        const fontColor = $editor.find('[name*="fontColor"]').val();
        const fontFamily = $editor.find('[name*="fontFamily"]').val();
        const fontSize = $editor.find('[name*="fontSize"]').val();
        const text = $editor.find('[name*="text"]').val();
        const bgColor = $editor.find('[name*="backgroundColor"]').val();

        const $example = $editor.find('[data-role="tv-message-example"]')

        $example
            .css({
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            })
            .text(text);
    }

    $messageEditors.each(function () {
        const $editor = $(this);

        $editor.find('input, select').off('change').on('change', function () {
            updateExample($editor);
        });

        updateExample($editor);
    })


}

$(document).ready(function () {
    const $tvMessageEditor = $(this).find('[data-role="tv-messages-editor"]');

    if ($tvMessageEditor.length) {
        bindTvMessageEditor($tvMessageEditor);
    }
})

