import $ from "jquery";

export class BaseSelect2Factory {
    additionalAjaxFilter = null
    parentModal = null

    constructor() {
        if (new.target === BaseSelect2Factory) {
            throw new Error("Cannot instantiate BaseSelect2Factory.");
        }
    }

    getTheme() {
        return 'bootstrap-5';
    }

    selectionAdapter() {
        return 'single';
    }

    allowClear() {
        return true;
    }

    ajaxUrl() {
        throw 'Please implement ajax url for ajax select2';
    }

    allowCreate() {
        return false;
    }

    templateResult(item) {
        return item.text;
    }

    templateSelection(item) {
        return item.text;
    }

    preloadUrl() {
        return this.ajaxUrl();
    }

    getPlaceholder() {
        return "Please select item..."
    }

    preload(data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "get",
                url: this.preloadUrl(),
                data: data,
                success: function (response) {
                    resolve(response.data.rows);
                },
                error: function (data) {
                    console.error('Unexpected response', data);
                    reject();
                }
            });
        });
    }

    setAdditionalFilter(filterCallback) {
        this.additionalAjaxFilter = filterCallback;

        return this;
    }

    setParentModal($element) {
        this.parentModal = $element;

        return this;
    }

    factory($element) {
        const config = {
            theme: this.getTheme(),
            tags: this.allowCreate(),
            templateResult: this.templateResult,
            templateSelection: this.templateSelection,
            allowClear: this.allowClear(),
            placeholder: this.getPlaceholder(),
            ajax: {
                selectionAdapter: this.selectionAdapter(),
                delay: 200,
                url: this.ajaxUrl(),
                dataType: 'json',
                data: (params) => {
                    const requestData = {
                        q: params.term,
                        type: 'public',
                        page: params.page,
                    };

                    if (this.additionalAjaxFilter) {
                        const additionalData = this.additionalAjaxFilter();

                        for (let field in additionalData) {
                            requestData[field] = additionalData[field];
                        }
                    }

                    return requestData;
                },
                processResults: (data) => {
                    return {
                        results: data.data.rows,
                        pagination: {
                            more: data.data.hasMore
                        }
                    };
                }
            }
        }

        if (this.parentModal) {
            console.log(this.parentModal);
            config.dropdownParent = this.parentModal;
        }

        if (this.allowCreate()) {
            config.insertTag = (data, tag) => {
                data.push(tag);
                $element.val(tag.id);
                $element.trigger('change');
            }
            config.createTag = (params) => {
                return {
                    id: params.term,
                    text: params.term,
                    newOption: true
                }
            }
        }

        $element.select2(config);

        BaseSelect2Factory.setPreselectedData($element);

        return $element;
    }

    /**
     * Установить предвыбранные данные для AJAX select2
     *
     * @param $select
     * @param selectedData
     * @param textField
     * @param idField
     * @param resolvePromise
     */
    static setPreselectedData($select, selectedData, textField, idField, resolvePromise) {
        if (selectedData) {

            const option = new Option(selectedData[textField], selectedData[idField], true, true);

            option.dataset.raw = JSON.stringify(selectedData);

            $select.append(option).trigger('change');

            $select.trigger({
                type: 'select2:select',
                params: {
                    data: selectedData
                }
            });

            setTimeout(() => {
                $select.trigger('change');
                if (resolvePromise) {
                    resolvePromise();
                }
            }, 100)
        } else {
            if (resolvePromise) {
                resolvePromise();
            }
        }
    }

    /**
     * Костылина на SELECT2, для того, чтобы вытаскивать данные для инитного состояния,
     * НЕЛЬЗЯ передать кастомный объект в стартовое состояние, и нужно костылять все через передачу data атрибута в option
     *
     * @param data
     * @returns {*}
     */
    static getRealData(data) {
        if (data && data.element && data.element.dataset.raw) {
            data = JSON.parse(data.element.dataset.raw);
        }

        return data;
    }
}