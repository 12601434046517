import {BaseSelect2Factory} from "./BaseSelect2Factory";
import $ from "jquery";
import {ExerciseFileItemSelect2Factory} from "./FileItemSelect2Factory";

export class ExerciseSelect2Factory extends BaseSelect2Factory {
    ajaxUrl() {
        return '/select2/exercise';
    }

    allowCreate() {
        return true;
    }

    allowClear() {
        return true;
    }

    templateResult = (exerciseFileItem) => {
        exerciseFileItem = ExerciseFileItemSelect2Factory.getRealData(exerciseFileItem);

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return '';
        }

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return 'Loading...';
        }

        return $(`<div class="exercise-file-card">
                            <div class="img">
                                <img src="${exerciseFileItem.img}" alt="" />
                            </div>
                            <div class="info-block">
                                <div class="names-block">
                                    <div class="text-exercise-name">${exerciseFileItem.text}</div>                                    
                                </div>
                            </div>
                        </div>`);
    }

    templateSelection(exerciseFileItem) {
        exerciseFileItem = ExerciseFileItemSelect2Factory.getRealData(exerciseFileItem);
        console.log('e', exerciseFileItem);

        if (!exerciseFileItem || !exerciseFileItem.id) {
            return '';
        }

        let imgHtml = ``;

        if(exerciseFileItem.id && exerciseFileItem.img) {
            imgHtml = `<div class="img img-mini">
                                <img src="${exerciseFileItem.img}" alt="" />
                            </div>`;
        }

        return $(`<div class="exercise-file-card">
                            ${imgHtml}
                            <div class="info-block">
                                <div class="names-block">
                                    <div class="text-exercise-name">${exerciseFileItem.text}</div>
                                </div>
                            </div>
                        </div>`);
    }

    factory($element) {
        $element.on('select2:open', function () {
            const val = $(this).find('option:selected').text();
            if (val !== '_new') {
                const id = $(this).parent().find('[aria-owns]').first().attr('aria-owns');
                $(`input[aria-controls="${id}"]`).val(val);
            }
        })
        return super.factory($element);
    }
}
