import $ from "jquery";
import {ExerciseFileItemSelect2Factory} from "./select2/FileItemSelect2Factory";

$(document).on('modal:rendered', function (e, $element) {
    const $modal = $element;
    const $inputsToSelect2 = $element.find('[data-role="exercise-file-item-select"]');

    const getSelect2AdditionalFilter = () => {
        const filter = {};

        $('[data-role="filter-field"]').each(function () {
            const formField = $(this).attr('name');
            const value = $(this).val();

            if (formField) {
                if ($(this).is(':checkbox')) {
                    if ($(this).is(':checked')) {
                        filter[formField] = value;
                    }
                } else {
                    filter[formField] = value;
                }
            }
        })

        return filter;
    }

    $inputsToSelect2.each(function () {
        const $newInput = (new ExerciseFileItemSelect2Factory())
            .setParentModal($modal)
            .setAdditionalFilter(getSelect2AdditionalFilter)
            .factory($(this))
        ;

        ExerciseFileItemSelect2Factory.BindToNearbyMiniImage($newInput);
    })
})