import $ from 'jquery';
import {Modal} from "bootstrap";
import {objectToQueryParams} from "./helpers/url";
import {createOverlaySpinner} from "./helpers/spinner";

function setVideo(source) {
    const $target = $('#exercise-video-preview');
    if ($target.length) {
        $target[0].src = (typeof source === 'string') ? source : URL.createObjectURL(source);
        $target.parent()[0].load();
    }
}

/**
 * Setup bulk edit form, to submit data via ajax.
 *
 * @param refreshControls setup additional JS actions, if they not applied to form DOM elements
 */
const setupBulkEditForm = (refreshControls) => {
    const $form = $('#exercise-bulk-edit-modal form');
    const $submitButton = $form.find('button[type="submit"]')
    const action = $form.attr('action');

    $form.submit(function (event) {
        event.preventDefault();

        $submitButton.attr('disabled', true);
        $submitButton.find('[role="status"]').removeClass('visually-hidden');

        $.ajax({
            type: "POST",
            url: action,
            data: $form.serialize(),
            success: function () {
                window.location.reload();
            },
            error: function (data) {
                if (data.status === 400 && false) {
                    $form.replaceWith(data.responseText);
                    setupBulkEditForm(true);
                } else {
                    console.error('Unexpected response', data);
                }
            },
        });
    });

    if (refreshControls) {
        $('#exercise-bulk-edit-modal form [data-select2-enabled]').select2({
            'theme': 'bootstrap-5'
        });
    }
}

const setupBulkDeleteForm = () => {
    const $form = $('#exercise-bulk-delete-modal form');
    const $submitButton = $form.find('button[type="submit"]')
    const action = $form.attr('action');

    $form.submit(function (event) {
        event.preventDefault();

        $submitButton.attr('disabled', true);
        $submitButton.find('[role="status"]').removeClass('visually-hidden');

        $.ajax({
            type: "POST",
            url: action,
            data: $form.serialize(),
            success: function () {
                window.location.reload();
            },
            error: function (data) {
                if (data.status === 400 && false) {
                    $form.replaceWith(data.responseText);
                    setupBulkEditForm(true);
                } else {
                    console.error('Unexpected response', data);
                }
            }
        });
    });
}

$(function () {
    $(document).on('click', '*[data-event="watch-video"]', function () {
        $('#watch-video-title').html($(this).data('title'));
        const $target = $('#watch-video-source');
        $target[0].src = '/file/original/' + $(this).data('id');
        $target.parent()[0].load();

        Modal.getOrCreateInstance(document.getElementById('watch-video-modal')).show();

        return false;
    });

    $(document).on('change', 'input[data-exercise-item-selector]', function () {
        const isOneItemChecked = $('input[data-exercise-item-selector]:checked').length > 0
        const isMultipleItemChecked = $('input[data-exercise-item-selector]:checked').length > 1
        const $btnCombine = $('[data-role="exercise-combine"]');

        $('[data-event="exercise-bulk-edit"]').attr('disabled', !isOneItemChecked);
        $('[data-event="exercise-bulk-delete"]').attr('disabled', !isOneItemChecked);

        $btnCombine.attr('disabled', !isMultipleItemChecked);

        const exerciseIds = [];

        $('input[data-exercise-item-selector]:checked').each(function () {
            const $row = $(this).closest('[data-row="exercise-item"]');
            exerciseIds.push($row.data('id'));
        })

        const baseHref = $btnCombine.data('base-href');
        $btnCombine.attr('href', baseHref + '?' + objectToQueryParams({exerciseIds: exerciseIds}))
    });

    $(document).on('change', 'input[data-exercise-item-selector-all]', function () {
        const isChecked = $(this).is(':checked');

        $('input[data-exercise-item-selector]').prop('checked', isChecked).trigger('change');
    });

    $(document).on('click', '[data-event="exercise-bulk-edit"]', function () {
        Modal.getOrCreateInstance(document.getElementById('exercise-bulk-edit-modal')).show();

        const $selectedExercisesElement = $('#exercise_bulk_update_form_exerciseItems');
        const ids = [];

        $('input[data-exercise-item-selector]:checked').each(function (index, $element) {
            const id = ($($element.closest('tr')).data('id'));
            ids.push(id);
        });

        $('#exercise-bulk-edit-counter').html(ids.length);

        $selectedExercisesElement.val(JSON.stringify(ids));
    });

    $(document).on('click', '[data-event="exercise-bulk-delete"]', function () {
        Modal.getOrCreateInstance(document.getElementById('exercise-bulk-delete-modal')).show();

        const $selectedExercisesElement = $('#exercise_bulk_delete_form_exerciseItems');
        const ids = [];

        $('input[data-exercise-item-selector]:checked').each(function (index, $element) {
            const id = ($($element.closest('tr')).data('id'));
            ids.push(id);
        });

        $('#exercise-bulk-delete-counter').html(ids.length);

        $selectedExercisesElement.val(JSON.stringify(ids));
    });

    $(document).on('click', '*[data-event="exercise-edit"]', function () {
        const name = $(this).data('name');
        const repeats = $(this).data('repeats');

        const muscles = $(this).data('muscles');
        const muscleSubGroups = $(this).data('muscleSubGroups');
        const movementTypes = $(this).data('movementTypes');
        const muscleGroup = $(this).data('musclesGroup');
        const difficultyLevel = $(this).data('difficultyLevel');

        const exerciseType = $(this).data('exerciseType');
        const equipments = $(this).data('equipments');
        const movementMode = $(this).data('movementMode');

        const isWarmup = !!$(this).data('is-warmup');
        const isHiccup = !!$(this).data('is-hiccup');
        const isOnline = !!$(this).data('is-online');

        const id = $(this).data('id');
        const video = $(this).data('video');
        $('form[name="exercise_item_form"]').attr('action', '/exercise/edit/' + id + '/');
        $('#exercise_item_form_name').val(name);
        $('#exercise_item_form_repeats').val(repeats);
        $('#exercise_item_form_musclesGroup').val(muscleGroup);
        $('#exercise_item_form_difficultyLevel').val(difficultyLevel);
        $('#exercise_item_form_muscles').val(muscles).trigger('change');
        $('#exercise_item_form_muscleSubGroups').val(muscleSubGroups).trigger('change');
        $('#exercise_item_form_movementTypes').val(movementTypes).trigger('change');
        $('#exercise_item_form_equipments').val(equipments).trigger('change');
        $('#exercise_item_form_exerciseType').val(exerciseType);
        $('#exercise_item_form_movementMode').val(movementMode);
        $('#exercise_item_form_isWarmup').attr('checked', isWarmup);
        $('#exercise_item_form_isHiccup').attr('checked', isHiccup);
        $('#exercise_item_form_isOnline').attr('checked', isOnline);

        setVideo(video);

        const $exerciseVideoPreview = $('#exercise-video-preview');

        if ($exerciseVideoPreview.length) {
            $('#exercise-video-preview').parent()[0].load();
        }

        Modal.getOrCreateInstance(document.getElementById('exercise-edit-modal')).show();

        $('form[name="exercise_item_form"]').unbind('submit');
        $('form[name="exercise_item_form"]').bind('submit', function (e) {
            e.preventDefault();

            const action = $(this).attr('action');
            const data = new FormData(this);

            $(this).find('[type=submit]').prop('disabled', true);

            $.ajax({
                type: "POST",
                url: action,
                data: data,
                processData: false,
                contentType: false,
                success: function () {
                    window.location.reload();
                },
                error: function (data) {
                    if (data.status === 400) {
                        alert('Error on update exercise.');
                    } else {
                        console.error('Unexpected response', data);
                    }
                }
            });
        });

        return false;
    });

    $(document).on('click', '*[data-event="create-exercise"]', function () {
        $('form[name="exercise_item_form"]').attr('action', '/exercise/create/');
        $('#exercise_item_form_name').val('');
        $('#exercise_item_form_muscle').val('');
        $('#exercise_item_form_videoFile').val('');
        $('#exercise_item_form_videoFile').attr('required', 'required');
        setVideo('');

        Modal.getOrCreateInstance(document.getElementById('exercise-edit-modal')).show();

        return false;
    });

    $(document).on('change', '#exercise_item_form_videoFile', function () {
        const [file] = this.files;
        const fileName = (file.name).replace(/[\+|\s]+|(\.mp4)/ig, ' ').trim();
        const $target = $('#exercise_item_form_name');

        if ($target.val().trim() === '') {
            $target.val(fileName);
        }

        setVideo(file);
    });

    $('form[name="exercise_item_form"]').on('submit', function () {
        const $submitButton = $(this).find('button[type="submit"]')
        $submitButton.attr('disabled', true);
    });

    $(document).on('modal:rendered', function (e, $element) {
        const $exerciseItemUpdateModal = $element.find('[name="exercise_file_item_update_form"]');

        if ($exerciseItemUpdateModal.length) {
            const $switchers = $element.find('[data-role="set-as-main-switcher"]');
            const $saveAllButton = $element.find('[data-role="save-all"]');
            const $modal = $element;

            $switchers.on('change', function () {
                $switchers.not(this).prop('checked', false);
            })


            $saveAllButton.bind('click', function () {
                let processed = 0;

                $modal.find('.modal-content').append(
                    createOverlaySpinner()
                )

                const $forms = $modal.find('form[name="exercise_file_item_update_form"]');

                $forms.each(function () {
                    const $form = $(this);

                    const formData = $form.serialize();

                    $.ajax({
                        type: $form.attr('method'),
                        url: $form.attr('action'),
                        data: formData,
                    }).then(() => {
                        processed++;
                        if (processed === $forms.length) {
                            location.reload();
                        }
                    });
                })
            });
        }
    });

    setupBulkEditForm();
    setupBulkDeleteForm();
});