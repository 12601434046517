import $ from 'jquery';
import {formatTime} from "./helpers/converters";

$(document).ready(function () {
    $(document).on('click', '*[data-need-confirm="true"]', function () {
        if (!confirm($(this).data('confirm-message'))) {
            return false;
        }
    });

    $(document).find('[data-role="human-timer-input"]').each(function () {
        const $input = $(this);
        const timerId = $input.attr('data-timer-id');
        const $display = $(`[data-role="human-timer-display"][data-timer-id="${timerId}"]`);

        $input.on('change', function () {
            const val = $(this).val();
            $display.text(formatTime(val));
        });

        $input.trigger('change');
    });
});