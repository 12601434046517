import $ from "jquery";
import {objectToQueryParams} from "./helpers/url";

const bindExerciseFileItemEditor = ($root) => {
    const exerciseRowSelector = '[data-role="exercise-file-item-row"]';
    const $itemCheckbox = $root.find('[data-role="select-row"]');
    const $allCheckbox = $root.find('[data-role="select-all"]');
    const $btnBulkUpdate = $root.find('[data-role="btn-bulk-update"]');
    const $btnBulkDelete = $root.find('[data-role="btn-bulk-delete"]');
    const $btnBulkOptimize = $root.find('[data-role="btn-bulk-optimize"]');

    $allCheckbox.bind('change', function () {
        const isChecked = $(this).is(':checked');

        $itemCheckbox.prop('checked', isChecked).trigger('change');
    })

    $itemCheckbox.bind('change', function () {
        const $rowCheckedInput = $('input[data-role="select-row"]:checked');
        const isOneItemChecked = $rowCheckedInput.length > 0
        const selectedFileItems = [];

        $btnBulkUpdate.attr('disabled', !isOneItemChecked);
        $btnBulkDelete.attr('disabled', !isOneItemChecked);
        $btnBulkOptimize.attr('disabled', !isOneItemChecked);

        $rowCheckedInput.each(function () {
            const fileItemId = $(this).closest(exerciseRowSelector).data('id');
            selectedFileItems.push(fileItemId);
        });

        $btnBulkUpdate.attr(
            'href',
            $btnBulkUpdate.data('href') + '?' + objectToQueryParams({fileItemIds: selectedFileItems})
        )
        $btnBulkDelete.attr(
            'href',
            $btnBulkDelete.data('href') + '?' + objectToQueryParams({fileItemIds: selectedFileItems})
        )
        $btnBulkOptimize.attr(
            'href',
            $btnBulkOptimize.data('href') + '?' + objectToQueryParams({fileItemIds: selectedFileItems})
        )
    })
}

$(document).ready(() => {
    bindExerciseFileItemEditor($('[data-role="exercise-file-item-editor"]'));
})
