import * as Sentry from "@sentry/browser";

if (process.env.APP_ENV === 'prod') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN_FRONT,
        release: "cory@1.0.0",
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
    });
}