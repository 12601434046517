import {apiClient} from "./ApiClient";
import Alert from "./ComponentAlerts";

$(document).ready(() => {
    const $studioCreateContainer = $('[data-container="studio-create"]');
    const $studioUpdateContainer = $('[data-container="studio-update"]');
    const $studioTvControlContainer = $('[data-container="studio-tv-control"]');

    const $countrySelect = $('[data-role="country-select"]');
    const $citySelect = $('[data-role="city-select"]');
    const $codeInput = $('[data-role="code-input"]');
    const $citiesInput = $('#cities');

    if ($studioCreateContainer.length || $studioUpdateContainer.length) {
        const citiesJson = $citiesInput.val();
        const citiesArray = JSON.parse(citiesJson);
        const citiesByCountry = {};

        citiesArray.forEach((c) => {
            if (!citiesByCountry[c.country.id]) {
                citiesByCountry[c.country.id] = [];
            }
            citiesByCountry[c.country.id].push(c);
        })

        const generateCode = () => {
            if (!$codeInput.length) {
                return;
            }

            const cityChar = $('#studio_create_form_city').find('option:selected').text()[0];
            const countryChar = $('#studio_create_form_country').find('option:selected').text()[0];
            const code = countryChar + cityChar +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10)
            ;

            $codeInput.val(code);
        }

        $countrySelect.on('change', function () {
            const countryId = $(this).val();
            const cities = citiesByCountry[countryId];

            $citySelect.text('');

            cities.forEach((c) => {
                $citySelect.append(`
                    <option value="${c.id}">${c.name}</option>
                `)
            })

            generateCode();
        });

        $citySelect.on('change', function () {
            generateCode();
        });
    }

    if($studioTvControlContainer.length) {
        const $btnReloadTv = $('button[data-role="btn-tv-reload"]');
        const $btnDebugTv = $('button[data-role="btn-tv-debug"]');

        $btnReloadTv.each(function() {
            const $btn = $(this);
            const studioId = $btn.data('studio-id');
            const tvId = $btn.data('tv-id');

            $btn.on('click', function() {
                apiClient.post('/api/trainer/reload-tv', {tvId: tvId, studioId: studioId})
                    .then((response) => {
                        if (response.isSuccess()) {
                            Alert.show(response.getMessage(), 'success');
                        }

                        if (response.isBadRequest()) {
                            Alert.show('Tv reload failed. ' + response.getMessage(), 'danger');
                        }

                        if (response.isError()) {
                            Alert.show('Unknown error', 'danger');
                        }
                    })
            })
        });
        $btnDebugTv.each(function() {
            const $btn = $(this);
            const studioId = $btn.data('studio-id');
            const tvId = $btn.data('tv-id');

            $btn.on('click', function() {
                apiClient.post('/api/trainer/debug-tv', {tvId: tvId, studioId: studioId})
                    .then((response) => {
                        if (response.isSuccess()) {
                            Alert.show(response.getMessage(), 'success');
                        }

                        if (response.isBadRequest()) {
                            Alert.show('Tv debug failed. ' + response.getMessage(), 'danger');
                        }

                        if (response.isError()) {
                            Alert.show('Unknown error', 'danger');
                        }
                    })
            })
        });
    }
});